import React from "react";
import { ClockCircleOutlined, InfoCircleOutlined, RetweetOutlined, CheckCircleOutlined } from "@ant-design/icons";

const ProjectConstants = new Object();


ProjectConstants.access_levels = {
  'quality_control': 'Quality Control',
  'owner': 'Owner (PM)',
  'co_owner': 'Co-Owner',
  'viewer': 'Viewer',
  'editor': 'Editor'
}

ProjectConstants.edit_access_levels = ['co_owner', 'owner', 'viewer', 'quality_control', 'editor'];

ProjectConstants.new_edit_access_levels = [
  {
    'label': 'Co-Owner',
    'value': 'co_owner'
  },
  {
    'label': 'Viewer',
    'value': 'viewer'
  },
  {
    'label': 'Quality Control',
    'value': 'quality_control'
  },
  {
    'label': 'Editor',
    'value': 'editor'
  }
]

ProjectConstants.access_actions = {
  'owner': ['invite', 'approve_project', 'delete_project', 'approve_entity', 'comment', 'create'],
  'co_owner': ['invite', 'approve_project', 'delete_project', 'approve_entity', 'comment', 'create'],
  'editor': ['invite', 'create', 'comment'],
  'quality_control': ['comment', 'approve_entity'],
  'viewer': [],
  'restricted': []
}

ProjectConstants.model_type_filters = [
  {
    label: "Product",
    value: "product",
  },
  {
    label: "Scenes",
    value: "scene",
  },
  {
    label: "Space",
    value: "room",
  },
  {
    label: "Prop",
    value: "prop",
  },
];

ProjectConstants.review_status_filters = [
  {
    label: "In Review",
    value: "in_review",
  },
  {
    label: "Review Needed",
    value: "review_needed",
  },
  {
    label: "Approved",
    value: "approved",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];

ProjectConstants.model_type_filters = [
    {
      label: "Product",
      value: "product",
    },
    {
      label: "Scenes",
      value: "scene",
    },
    {
      label: "Space",
      value: "room",
    },
    {
      label: "Prop",
      value: "prop",
    },
  ];
  ProjectConstants.review_status_filters = [
    {
      label: "In Review",
      value: "in_review",
    },
    {
      label: "Review Needed",
      value: "review_needed",
    },
    {
      label: "Approved",
      value: "approved",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ];

  ProjectConstants.status_data = {
    rejected: {
      icon: <ClockCircleOutlined />,
      title: "ALL3D is Fixing",
    },
    approved: {
      icon: <CheckCircleOutlined style={{ color: "green" }} />,
      title: "Model Approved",
    },
    in_review: {
      icon: <RetweetOutlined style={{ color: "grey" }} />,
      title: "Needs Review",
    },
    in_progress: {
      icon: <ClockCircleOutlined style={{ color: "green" }} />,
      title: "ALL3D is Creating",
    },
    incomplete: {
      icon: <InfoCircleOutlined style={{ color: "grey" }} />,
      title: "Model is Incomplete",
    },
  };

  ProjectConstants.scene_status_data = {
    in_progress: {
      icon: <ClockCircleOutlined style={{ color: "green" }} />,
      title: "Scene in Progress",
    },
    incomplete: {
      icon: <InfoCircleOutlined style={{ color: "grey" }} />,
      title: "Scene is Incomplete",
    },
    copy_in_progress: {
        icon: <InfoCircleOutlined style={{ color: "grey" }} />,
        title: "Copy in Progress",
    }
  };

  ProjectConstants.entity_data = {
    product: {
      title: "Product",
      className: "product-tag",
      select_id: "id",
    },
    prop: {
      title: "Prop",
      className: "prop-tag",
      select_id: "id",
    },
    room: {
      title: "Space",
      className: "space-tag",
      select_id: "room_id",
    },
    scene: {
      title: "Scene",
      className: "scene-tag",
      select_id: "id"
    },
  };

export default ProjectConstants;