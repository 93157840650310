import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Modal, Button, Col, Input, Tooltip, Tabs } from 'antd';
import { FilterOutlined, SyncOutlined } from '@ant-design/icons';
import ProductGridWrapper from '../ProductGridWrapper/ProductGridWrapper';
import ProductFiltersDrawer from '../ProductFiltersDrawer/ProductFilterDrawer'
import * as Utilities from '../../Utilities';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext'
import './AddProductsToScene.scss';

const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const IS_MSPROVIDER = localStorage.getItem('is_msprovider');
const MANAGED_SHARED_ENTITES = localStorage.getItem("managed_shared_entites");

const { TabPane } = Tabs;

const AddProductsToScene = (props) => {
    const { add_product_modal, toggleAddProductModal, addProductsToScene, showAddProductsError,
        setAddProductsLoader, space_areas, collection_id, deletedProducts, updateSceneProductsList, 
        restoreProducts, updateSceneCollection, collection_products, project_ids } = props;

    const [loader, setLoader] = useState(true);
    const [libraryType, setLibraryType] = useState((IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME != null) ? "selectedCustomerOwned" : "OwnedLibrary");
    const [productsAddedToScene, setProductsAddedToScene] = useState([]);
    const [productsRestoredToScene, setProductsRestoredToScene] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [storeData, setStoreData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const [productsInScene, setProductsInScene] = useState([]);
    const [filtersDrawer, setFiltersDrawer] = useState(false);
    const [productCategoriesData, setProductCategoriesData] = useState([])
    const [productParentCateogries, setProductParentCateogries] = useState({})
    const [productMaterialsData, setProductMaterialsData] = useState([]);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [appliedFiltersList, setAppliedFiltersList] = useState([])
    const [filterTagFunction, setFilterTagFunction] = useState(null);
    const [returnSearchValFunction, setReturnSearchValFunction] = useState(null);
    const [productsForLibrary, setProductsForLibrary] = useState([]);
    const [maxPriceProduct, setMaxPriceProduct] = useState(0);
    const [currentTabType, setCurrentTabType] = useState("5");
    const [currentMSPTabType, setCurrentMSPTabType] = useState("5");
    const [currentProjectProductTabType, setCurrentProjectProductTabType] = useState("5");
    const [storeDataLoader, setStoreDataLoader] = useState(false);
    const [allStoreDataLoader, setAllStoreDataLoader] = useState(true);
    const [requestStatusProduct, setRequestStatusProduct] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [productLoader, setProductLoader] = useState(true);
    const [originalProjectProductsData, setOriginalProjectProductsData] = useState([]);
    const [MSPSelectedUserOwnedData, setMSPSelectedUserOwnedData] = useState([]);
    const [MSPSelectedUserSharedData, setMSPSelectedUserSharedData] = useState([]);
    const [ownedData, setOwnedData] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [initialLoadShared, setInitialLoadShared] = useState(true);
    const [initialLoadOwned, setInitialLoadOwned] = useState(true);
    const [sharedProductLoader, setSharedProductLoader] = useState(true);
    const [ownedProductLoader, setOwnedProductLoader] = useState(true);  
    const [sharedTabType, setSharedTabType] = useState('all');
    const [mspSharedTabType, setMspSharedTabType] = useState('all');

    const getFilteredLibraryData = (currentData, modelStatus) => {
        if (modelStatus == -1)
            return currentData;
        let newData = []
        currentData.filter ((item) => {
            if (item.model_status == modelStatus) {
                newData.push(item)
            }
        })
        return newData
    }

    const getFilteredSharedLibraryData = (currentData, type) => {
        if(type == 'all'){
            return currentData;
        }
        else if(type == 'props'){
            let newData = []
            currentData.filter ((item) => {
                if (item.shared_product_type == type &&  item.is_store_item != 1) {
                    newData.push(item);
                }
            })
            return newData
        }
        else{
            let newData = []
            currentData.filter ((item) => {
                if (item.shared_product_type == type) {
                    newData.push(item)
                }
            })
            return newData
        }
    }
    
    const customSortDeletedProductsData = (a, b) => {
        if (deletedProducts.includes(a.id.toString())) {
            return -1;
        }
        return 1;
    }

    const rearrangeDeletedProductsData = (unsortedData) => {
        let sortedData = unsortedData;
        if (deletedProducts.length > 0) {
            sortedData = sortedData.sort(customSortDeletedProductsData);
        }
        return sortedData;
    }

    const checkFilteredResults = (productsData) => {
        productsData = rearrangeDeletedProductsData(productsData);
        let data = [];
        if(searchValue != '' && !filtersApplied) {
            data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, productsData);
        } else if (searchValue == '' && filtersApplied) {
            data = productsData.filter((entity) => { return returnSearchValFunction(entity) == true })
            data = Utilities.getRelevanceBasedFilteredResults(data, appliedFiltersList)
        } else if (searchValue != '' && filtersApplied) {
            let filter_data = productsData.filter((entity) => { return returnSearchValFunction(entity) == true })
            data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, filter_data);
            data = Utilities.getRelevanceBasedFilteredResults(data, appliedFiltersList)
        } else{
            data = productsData;
        }
        return data;
    }

    const tabs = [
        {
            key: 'ProjectProducts',
            title: "Project Products",
            data: displayedData,
            originalData: originalProjectProductsData,
            length: checkFilteredResults(getFilteredLibraryData(originalProjectProductsData, parseInt(currentProjectProductTabType))).length,
            loader: loader
        },
        {
            key: "selectedCustomerOwned",
            title: `${MANAGED_CUSTOMER_USERNAME}'s Products`,
            data: displayedData,
            originalData: MSPSelectedUserOwnedData,
            length: checkFilteredResults(getFilteredLibraryData(MSPSelectedUserOwnedData, parseInt(currentMSPTabType))).length,
            loader: loader
        },
        {
            key: "selectedCustomerOther",
            title: `${MANAGED_CUSTOMER_USERNAME}'s Other Products`,
            data: displayedData,
            originalData: MSPSelectedUserSharedData,
            length: checkFilteredResults(getFilteredSharedLibraryData(MSPSelectedUserSharedData, mspSharedTabType)).length,
            loader: loader
        },
        {
            key: "OwnedLibrary",
            title: "Your Products",
            data: displayedData,
            originalData: ownedData,
            length: checkFilteredResults(getFilteredLibraryData(ownedData, parseInt(currentTabType))).length,
            loader: loader
        },
        {
            key: "OtherLibrary",
            title: "Other Products",
            data: displayedData,
            originalData: otherData,
            length: checkFilteredResults(getFilteredSharedLibraryData(otherData, sharedTabType)).length,
            loader: loader
        },
        {
            key: "store",
            title: "Store",
            data: displayedData,
            originalData: storeData,
            length: checkFilteredResults(storeData).length,
            loader: storeDataLoader,
        },
    ];


    useEffect(() => {
        loadProducts();
        setProductCategoryData();
        loadUserRequestStatusProducts();
        loadProductMaterialsData();
    }, []);

    useEffect(() => {
        if(project_ids.length > 0){
            getProjectProductData()
            setLibraryType("ProjectProducts")
        }
    }, [project_ids]);

    useEffect(() => {
        if (collection_id != -1 || add_product_modal == true) {
            loadProductsInScene();
        }
    }, [collection_id, add_product_modal]);

    useEffect(() => {
        if (libraryType == "ProjectProducts"){
            let currentDisplayedData = getFilteredLibraryData(originalProjectProductsData, parseInt(currentProjectProductTabType));
            let sortedData = rearrangeDeletedProductsData(currentDisplayedData);
            setDisplayedData(sortedData);
        }
        else if (IS_MSPROVIDER && libraryType == "selectedCustomerOwned") {
            let currentDisplayedData = getFilteredLibraryData(MSPSelectedUserOwnedData, parseInt(currentMSPTabType));
            let sortedData = rearrangeDeletedProductsData(currentDisplayedData);
            setDisplayedData(sortedData);
        }
        else if (IS_MSPROVIDER && libraryType == "selectedCustomerOther") {
            let currentDisplayedData = getFilteredSharedLibraryData(MSPSelectedUserSharedData, mspSharedTabType)
            let sortedData = rearrangeDeletedProductsData(currentDisplayedData);
            setDisplayedData(sortedData);
        }
        else if (IS_MSPROVIDER && libraryType == "OwnedLibrary") {
            let currentDisplayedData = getFilteredLibraryData(ownedData, parseInt(currentTabType));
            let sortedData = rearrangeDeletedProductsData(currentDisplayedData);
            setDisplayedData(sortedData);
        } else if (libraryType == "OwnedLibrary") {
            let sortedData = rearrangeDeletedProductsData(ownedData);
            setDisplayedData(sortedData);
        } else if (libraryType == "OtherLibrary") {
            let currentDisplayedData = getFilteredSharedLibraryData(otherData, sharedTabType)
            let sortedData = rearrangeDeletedProductsData(currentDisplayedData);
            setDisplayedData(sortedData);
        } else if (libraryType == "store") {
            let sortedData = rearrangeDeletedProductsData(storeData);
            setDisplayedData(sortedData);
        } 
    }, [libraryType, ownedData, otherData, storeData, deletedProducts, currentTabType, currentMSPTabType, currentProjectProductTabType,mspSharedTabType, sharedTabType]);

    const loadProductsInScene = () => {
        updateSceneProductsList((sceneProducts) => {
            setProductsInScene(sceneProducts);
        })
    }

    const setProductCategoryData = () => {
        let payload = {
            output: 'tree'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setProductCategoriesData(res.data);
            });

        payload = {
            output: 'serial'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setProductParentCateogries(res.data);
            });
    }

    const loadProductMaterialsData = () => {
        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
            .then(res => {
                let categoriesData = Utilities.getMaterialsData(res.data);
                for (let i = 1; i <= (res.data).length; i++) {
                    if (res.data[i - 1].parent_id == "") {
                        data.push({
                            key: res.data[i - 1]['material_id'],
                            title: res.data[i - 1]['name'],
                            value: res.data[i - 1]['name'],
                            children: categoriesData[res.data[i - 1]['material_id']],
                        });
                    }

                }
                setProductMaterialsData(data);
            });
    }

    const loadProducts = () => {
        if (IS_MSPROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            getCustomerDataForMsprovider("owned");
            getCustomerDataForMsprovider("shared");
        } 
        getProductData("owned", true);
        getProductData("shared", true);
        getStoreItems(true);
    }

    useEffect(() => {
        if (!initialLoadOwned && ownedProductLoader) {
            getProductData("owned");
        }
      }, [initialLoadOwned]);

    useEffect(() => {
        if (!initialLoadShared && sharedProductLoader) {
            getProductData("shared");
        }
    }, [initialLoadShared]);

    const getTabTitle = (tab) => {
        if (libraryType == tab.key && searchValue == "" && !filtersApplied && tab.key != "store")
        {
            return tab.title + ` (${displayedData.length})`;
        }
        if (tab.key == "store" && allStoreDataLoader)
            return tab.title;
        else return tab.title + ` (${tab.length})`;
    };

    const getCustomerDataForMsprovider = (type) => {
        const payload = getProductPayload(type, false, -1, MANAGED_CUSTOMER_USERNAME, MANAGED_COMPANY_ID, MANAGED_SHARED_ENTITES, true);
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((res) => {
                if (res.data) {
                    const dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data)
                    type === 'owned' && setMSPSelectedUserOwnedData(dataWithKeywords);
                    type === 'shared' && setMSPSelectedUserSharedData(dataWithKeywords);
                }
            });
    }

    const getProjectProductData = () => {
        setLoader(true);
        let payload = getProjectProductsPayload(project_ids);
        console.log("Payload: ", payload)
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((response) => {
                if (response.data) {
                    let owned_products = Utilities.getProductsDataWithKeywords(response.data);;
                    setOriginalProjectProductsData(owned_products);
                    setProductLoader(false);
                    setLoader(false);
                }
            })
            .catch((error) => {
            });
    }

    let getProjectProductsPayload = (project_ids) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "platform", "placement_type", "material_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        payload["filter_string"] =  `(project_ids__in=[${project_ids.join(",")}]) && (model_status__in=[4, 5])`

        return payload;
    }

    const getProductData = async (type, initial = false) => {
        if (!initial) {
            setLoader(true);
          } else {
            if (type == 'shared') {
              setInitialLoadShared(true);
            } else {
              setInitialLoadOwned(true);
            }
        }
        let payload = getProductPayload(type, initial, -1);
        await axios.post(ENVIRONMENT.LIST_PRODUCT, payload).then((res) => {
            if (res.data) {
                const dataWithKeywords = Utilities.getProductsDataWithKeywords(
                    res.data
                );
                if (type == "shared") {
                    let products = Utilities.getProductsDataWithKeywords(dataWithKeywords);
                    setOtherData(products);
                } else {
                    let products = Utilities.getProductsDataWithKeywords(dataWithKeywords);
                    setOwnedData(products);
                }
            }
            if (initial) {
                if (type == 'shared') {
                  setInitialLoadShared(false);
                } else {
                  setInitialLoadOwned(false);
                }
              } else {
                if (type == 'shared') {
                  setSharedProductLoader(false);
                } else {
                  setOwnedProductLoader(false);
                }
                setLoader(false)
              }
        });
    };

    const getProductPayload = (type, initial, sequence_id, current_customer = CUSTOMER_USERNAME, current_company_id = COMPANY_ID, current_shared_entities = SHARED_ENTITIES, is_customer_data = false) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "color_name", "materials",
                "platform", "style_category", "gtin", "tags", "product_model_type", "placement_type",
                 "material_type"],
        }

        if (IS_MSPROVIDER || sequence_id == -1) {
            payload["order_by"] = "last_modified_stamp desc"
        } else {
            payload['required_fields'].push('folder_id', 'folder_name', 'parent_folder_id',
                'library_order', 'entity_order')
            payload["order_by"] = "library_order asc,entity_order asc"
        }
        let filter_string = "";
        let shared_username = "";
        let customer_username = "customer_username__exact='" + current_customer + "'"
        let customer_username_notexact = "customer_username__notexact='" + current_customer + "'"
        let company_id_notexact = ""

        let company_id = "";
        let model_status = ""
        if(IS_MSPROVIDER && type == "owned"){
            model_status = "model_status__in=[3,4,5]"
        }
        else{
            model_status = "model_status__in=[5]"
        }

        let is_hidden = "is_hidden__not=true"
        let shared_hidden = "shared_hidden__not=true"
        let sequence_id_value = "sequence_id__exact=" + sequence_id
    

        if (type == 'shared') {
            payload['required_fields'].push('shared_product_type')
            shared_username = "shared_username__exact='" + current_customer + "'"
            filter_string = "(" + shared_username + "&&" + shared_hidden + ")"

            if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
                company_id_notexact = "company_id__notexact=" + current_company_id + "||company_id__isnull=true"
                filter_string = filter_string + "&&(" + company_id_notexact + ")&&("+customer_username_notexact+")";
            } else {
                filter_string = filter_string + "&&("+customer_username_notexact+")";
            }

        } else if (current_shared_entities != undefined && current_company_id && current_shared_entities.split("_").includes("product")) {
            company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")"
        } else {
            if (IS_MSPROVIDER && !is_customer_data) {
                company_id = "company_id__isnull=true"
                let requested_for = "requested_for__isnull=true"
                filter_string = "(" + company_id + "&&" + customer_username + ")&&(" + is_hidden + ")&&(" + requested_for + ")"
            } else {
                filter_string = "(" + customer_username + "&&" + is_hidden + ")"
            }
        }
        filter_string = filter_string + "&&(" + model_status + ")"
        if (sequence_id != -1) {
            filter_string = filter_string + "&&(" + sequence_id_value + "||sequence_id__isnull=true)"
        }
        if (current_company_id == ENVIRONMENT.WALMART_COMPANY_ID)
        {
            if(type == 'shared'){
                company_id = "company_id__exact=" + current_company_id + "||additional_company_ids__contains='" + current_company_id + "'"
                filter_string = '(' + filter_string + ')||(' + `(${company_id}||${customer_username})&&(${is_hidden})&&(${model_status})&&(product_model_type__exact='props'))`
            }
            else {
                filter_string = '(' + filter_string + `)&&(product_model_type__notexact='props')`
            }
        }
        filter_string = filter_string + "&&(has_active_project__is=false)"
        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        if(localStorage.getItem('super_admin_username')){
            payload['is_customer_assumed_role'] = true
            payload['super_admin_username'] = localStorage.getItem('super_admin_username')
        }
        return payload;
    }


    const getStoreItems = (initial = false) => {
        let payload = {
            username: CUSTOMER_USERNAME,
            item_type: 'product',
        }
        if (initial) {
            payload.limit = "";
            setStoreDataLoader(true);
        }
        axios.post(ENVIRONMENT.STORE_LIST, payload)
            .then((res) => {
                let store_products = Utilities.getProductsDataWithKeywords(res.data, "store");
                store_products = store_products.sort(customSort);

                let max_price = store_products.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
                setMaxPriceProduct(max_price);
                setStoreData(store_products);
                if (initial) {
                    setStoreDataLoader(false);
                } else {
                    setAllStoreDataLoader(false);
                }
            });
    }

    useEffect(() => {
        if (storeDataLoader == false && storeData.length > 0) {
            getStoreItems();
        }
    }, [storeDataLoader]);

    // Custom sorting function
    const customSort = (a, b) => {
        // Parse prices as numbers or set them to Infinity if empty or 0
        const priceA = a.price === "" ? Infinity : parseFloat(a.price || 0.0);
        const priceB = b.price === "" ? Infinity : parseFloat(b.price || 0.0);

        // Compare prices
        if (priceA < priceB) {
            return 1;
        } else if (priceA > priceB) {
            return -1;
        } else {
            // If prices are equal, maintain the original order
            return b.id - a.id;
        }
    }

    const loadUserRequestStatusProducts = async () => {
        const check_payload = {
            username: localStorage.getItem("username"),
            action: 'check',
            request_type: 'product_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            setRequestStatusProduct(res.data['request_allowed']);
        });
    };

    const cancelAddProductModal = () => {
        setProductsAddedToScene([])
        setProductsForLibrary([])
        setProductsRestoredToScene([]);
        toggleAddProductModal(false);
        setSearchValue("");
        setDisplayedData(project_ids.length == 0 ? ownedData : originalProjectProductsData);
        setCurrentTabType("5");
        setCurrentMSPTabType("5");
        setCurrentProjectProductTabType("5");
        setLibraryType(project_ids.length == 0 ? "OwnedLibrary" : "ProjectProducts");
    }

    const openFilters = () => {
        setFiltersDrawer(true);
    };

    const onChangeLibraryType = (e) => {
        setLibraryType(e);
    }

    const changeSearchValue = (value) => {
        setSearchValue(value);
    }

    const restoreProductsToScene = () => {
        toggleAddProductModal(false);
        let productsList = [];

        // check if restored product is in collection
        productsRestoredToScene.forEach(product => {
            if (!collection_products.includes(product.id.toString())) {
                    let productInfo = {}
                    productInfo["product_id"] = product.id.toString();
                    if (space_areas.length > 0) {
                        productInfo["area_name"] = space_areas[0];
                    }
                    productsList.push(productInfo)
            }
        });

        // if restored product is not in collection add it
        if (productsList.length > 0) {
            let payload = {
                "collection_id": collection_id,
                "products_list": productsList,
                "update_collection_metadata": true,
                'username': CUSTOMER_USERNAME
            }
            if (space_areas.length > 0) {
                payload["space_allocation"] = true
            }
            axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload).then(res => {
            });
        }

        restoreProducts(productsRestoredToScene);
        setProductsRestoredToScene([]);
    }

    const onChangeSharedTabType = (e, type) => {
        if (type == 'OtherLibrary') {
            setSharedTabType(e.target.value);
        } else if ('selectedCustomerOther') {
            setMspSharedTabType(e.target.value);
        }
    }

    const addProductsToCollectionAndScene = () => {
        setAddProductsLoader(true);
        toggleAddProductModal(false)
        setSearchValue("");
        setDisplayedData(ownedData);
        setCurrentTabType("5");
        setCurrentMSPTabType("5");
        setCurrentProjectProductTabType("5");
        setLibraryType(project_ids.length == 0 ? "OwnedLibrary" : "ProjectProducts");
        let productAssetsInfo = []
        productsAddedToScene.forEach(product => {
            let productInfo = {}
            productInfo["product_id"] = product.product_id;
            if (product.area) {
                productInfo["area_name"] = product.area;
            }
            productAssetsInfo.push(productInfo)
        })
        let payload = {
            "collection_id": collection_id,
            "products_list": productAssetsInfo,
            "update_collection_metadata": true,
            'username': CUSTOMER_USERNAME
        }
        if (space_areas.length > 0) {
            payload["space_allocation"] = true
        }
        if (productsForLibrary.length > 0) {
            payload['add_to_library'] = productsForLibrary
            payload['shared_product_type'] = 'added_from_store'
        }
        axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
            .then(res => {
                if (productsForLibrary.length > 0) {
                    setProductsForLibrary([])
                    refreshProducts();
                }
                if (res.data && res.data.length > 0) {
                    addProductsToScene(productsAddedToScene);
                    updateSceneCollection(res.data)
                    setProductsAddedToScene([])
                    let productsInScene = [];
                    res.data.forEach(product => {
                        productsInScene.push(product.id.toString())
                    })
                    setProductsInScene(productsInScene)
                }
                else {
                    setAddProductsLoader(false);
                    showAddProductsError();
                }
            });
    }

    const refreshProducts = () => {
        loadProductsInScene();
        loadProducts();
    }


    const onProductTabChange = (e, type) => {
        if (type == 'OwnedLibrary') {
            setCurrentTabType(e.target.value)
        } else if (type == 'selectedCustomerOwned') {
            setCurrentMSPTabType(e.target.value);
        } else if (type == 'ProjectProducts') {
            setCurrentProjectProductTabType(e.target.value);
        }
    }

    useEffect(() => {
        if (!loader && add_product_modal) {
            const nextNonEmptyTab = tabs.find(tab => tab.originalData.length > 0);
            if (nextNonEmptyTab && libraryType !== nextNonEmptyTab.key) {
                setLibraryType(nextNonEmptyTab.key);
            }
        }
    }, [add_product_modal, loader]);
    return (
        <Modal
            destroyOnClose={true}
            closable={true}
            onCancel={cancelAddProductModal}
            className="add-product-modal"
            bodyStyle={{ padding: 0 }}
            footer={null}
            visible={add_product_modal}
        >
            <>
                <Row className="header-container">
                    <div className="swap-heading-tag" style={{ width: "190px" }}>
                        <span className="manrope f-14">Add Product(s) to Scene</span>
                    </div>
                </Row>
                <Row className="justify-space-between swap-container">
                    <Col span={8} className="justify-in-start">
                        <span className="manrope f-20 w-600">Choose a product below to add in the scene</span>
                    </Col>
                    <Col span={16} className="justify-in-end">
                        <Input className="manrope f-14 grey-99 pd-8 search-bar-width search-bar-border" placeholder="Search Products"
                            onChange={e => { changeSearchValue(e.target.value) }} />

                        <Tooltip title={<span className="manrope f-12 white">Apply Filters</span>}>
                            <Button icon={<FilterOutlined className='justify-in-center filter-icon' />} className="icon-button-container filter-btn-icon ml-8" onClick={openFilters}>
                            </Button>
                        </Tooltip>
                        <Tooltip title={<span className="manrope f-12 white">Refresh</span>}>
                            <Button icon={<SyncOutlined className='justify-in-center filter-icon' />} className="icon-button-container filter-btn-icon ml-8" onClick={refreshProducts}>
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <AddProductsToSceneContext.Provider
                    value={{
                        loader: loader,
                        filtersApplied: filtersApplied,
                        setFiltersApplied: setFiltersApplied,
                        setAppliedFiltersList: setAppliedFiltersList,
                        appliedFiltersList: appliedFiltersList,
                        displayedData: displayedData,
                        libraryType: libraryType,
                        searchValue: searchValue,
                        space_areas: space_areas,
                        collection_products: productsInScene,
                        deletedProducts: deletedProducts,
                        collection_id: collection_id,
                        productsAddedToScene: productsAddedToScene,
                        setProductsAddedToScene: setProductsAddedToScene,
                        productsRestoredToScene: productsRestoredToScene,
                        setProductsRestoredToScene: setProductsRestoredToScene,
                        filtersDrawer: filtersDrawer,
                        setFiltersDrawer: setFiltersDrawer,
                        productCategoriesData: productCategoriesData,
                        productParentCateogries: productParentCateogries,
                        productMaterialsData: productMaterialsData,
                        onChangeLibraryType: onChangeLibraryType,
                        changeSearchValue: changeSearchValue,
                        openFilters: openFilters,
                        setFilterTagFunction: setFilterTagFunction,
                        filterTagFunction: filterTagFunction,
                        returnSearchValFunction: returnSearchValFunction,
                        setReturnSearchValFunction: setReturnSearchValFunction,
                        refreshProducts: refreshProducts,
                        productsForLibrary: productsForLibrary,
                        setProductsForLibrary: setProductsForLibrary,
                        maxPriceProduct: maxPriceProduct,
                        onProductTabChange: onProductTabChange,
                        currentTabType: currentTabType,
                        currentMSPTabType: currentMSPTabType,
                        currentProjectProductTabType: currentProjectProductTabType,
                        storeDataLoader: storeDataLoader,
                        allStoreDataLoader: allStoreDataLoader,
                        requestStatusProduct: requestStatusProduct,
                        initialLoadOwned: initialLoadOwned,
                        initialLoadShared: initialLoadShared,
                        originalProjectProductsData: originalProjectProductsData,
                        project_ids: project_ids,
                        mspSharedTabType: mspSharedTabType,
                        sharedTabType: sharedTabType,
                        onChangeSharedTabType: onChangeSharedTabType,
                        checkFilteredResults: checkFilteredResults
                    }}>
                    <div className="swap-container">
                        <Tabs
                            onChange={(key) => onChangeLibraryType(key)}
                            className="product-tab library"
                            tabBarGutter={16}
                            size="large"
                            activeKey={libraryType}
                        >
                            {tabs?.map((tab, index) => {
                                return (index === 1 && (!IS_MSPROVIDER || !MANAGED_CUSTOMER_USERNAME)) || (index === 2 && (!IS_MSPROVIDER || !MANAGED_CUSTOMER_USERNAME)) || (index === 0 && project_ids.length == 0 ) ? null : (
                                    <TabPane
                                        tab={getTabTitle(tab)}
                                        key={tab.key}
                                        style={{ padding: "0px 10px" }}
                                    >
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                        <Row className='w-100'>
                            {filterTagFunction}
                        </Row>
                        <ProductGridWrapper />
                    </div>
                    <ProductFiltersDrawer />
                </AddProductsToSceneContext.Provider>
                
                <Row className="swap-modal-footer justify-in-end" onClick={() => { }}>
                    <Button
                        style={{ marginRight: 10 }}
                        className="outline-red-btn square font-12 manrope"
                        onClick={cancelAddProductModal}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="modal-okay square font-14"
                        disabled={Object.keys(productsAddedToScene).length === 0 && productsRestoredToScene.length == 0}
                        onClick={()=>{
                            if (Object.keys(productsAddedToScene).length > 0) {
                                addProductsToCollectionAndScene()
                            }
                            if (productsRestoredToScene.length > 0) {
                                restoreProductsToScene();
                            }
                        }
                    }
                    >
                        Continue
                    </Button>
                </Row>
            </>
        </Modal>
    );

}

export default AddProductsToScene;
