import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  ArrowLeftOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Form,
  Breadcrumb,
  Tabs,
  Button,
  Modal,
  message
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import ProjectsList from "./ProjectsList";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import { Header } from 'antd/lib/layout/layout';
import _ from "lodash";
const { TabPane } = Tabs;

let IS_MS_PROVIDER = FileConstants.isMSProvider;
let CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME
const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');

const DashboardProjects = (props) => {
  const [tabActiveKey, setTabActiveKey] = useState('active');
  const [reviewData, setReviewData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filteredInProgressData, setFilteredInProgressData] = useState([]);
  const [filteredReviewData, setFilteredReviewData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(false);
  const [tempProjectCount, setTempProjectCount] = useState([]);
  const [currentProjectAccessLevel, setCurrentProjectAccessLevel] = useState("");

  const tabs = [
    {
      key: 'active',
      title: 'Active Projects',
      data: filteredReviewData
    },
    {
      key: 'requested',
      title: 'Requested Projects',
      data: filteredInProgressData
    }
  ];

  const handleTabChange = (key) => {
    setTabActiveKey(key);
    window.history.pushState(null, null, `?tab=${key}`);
  };

  const getProjectPayload = (type) => {
    let status = "";
    if (type === "requested") {
      status = "status__exact='in_progress'"
    } else {
      status = "status__in=['in_review', 'ready_for_scenes']"
    }
    let payload = {
      order_by: "last_modified_stamp",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "thumbnail",
        "company_id",
        "meta_data",
        "status",
        "last_modified",
        "access_level",
        "last_modified_stamp"
      ],
      filter_string: `(${status}&&username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','viewer','co_owner','quality_control', 'editor'])&&(is_hidden__not=true)`,
    };
    return payload;
  }

  const fetchProjectData = async (type) => {
    let payload = getProjectPayload(type);

    await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {  
      switch(type) {
        case 'active':
          setReviewData(res.data);
          setFilteredReviewData(res.data);
          break;
        case 'requested':
          setInProgressData(res.data);
          setFilteredInProgressData(res.data);
          break;
      }
    });
  };
  
  useEffect(() => {
    getOwnedProjectCount();
    IS_MS_PROVIDER = FileConstants.isMSProvider;
    CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME

    const fetchAllProjectData = async (type) => {
      setIsDataLoading(true);
      await fetchProjectData('active');
      await fetchProjectData('requested');
      setIsDataLoading(false);
    }

    fetchAllProjectData();
  }, []);
  
  const getOwnedProjectCount = () => {
    const counts = localStorage.getItem("entity_counts");
    if (counts) {
        let entityCounts = JSON.parse(counts);
        setTempProjectCount(entityCounts);
    }
    else {
        let payload = {
            username: CUSTOMER_USERNAME,
            project_homepage: true
        };
        if (COMPANY_ID != undefined) {
            payload.company_id = COMPANY_ID;
        };
        
        if(IS_MS_PROVIDER){
            if(MANAGED_CUSTOMER_USERNAME){
                payload['username'] = MANAGED_CUSTOMER_USERNAME
            }
            if(MANAGED_COMPANY_ID){
                payload['company_id'] = MANAGED_COMPANY_ID
            }
        }

        axios.post(ENVIRONMENT.HOME_SCREEN_STATS, payload).then((res) => {
            let approved_project = {
                "type": "approved",
                "count": (res.data.library_data.find(ele => ele.type === "project"))?.count
            }
            res.data.library_data = res.data.library_data.filter(ele => ele.type !== "project")
            let active_project = {
                "type": "active",
                "count": (res.data.review_data.find(ele => ele.type === "project"))?.count
            }
            let requested_project = {
                "type": "requested",
                "count": (res.data.in_progress_data.find(ele => ele.type === "project"))?.count
            }
            //Setting Entity Counts in localStorage
            let entity_counts = _.cloneDeep(res.data.library_data);
            
            entity_counts.push(approved_project)
            entity_counts.push(active_project)
            entity_counts.push(requested_project)
            
            localStorage.setItem('entity_counts', JSON.stringify(entity_counts));
            setTempProjectCount(entity_counts);
        })
    }
  }

  const searchFilter = (searchValue, data) => {
    return data.filter((item) => {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  useEffect(() => {
      const filteredInProgressData = searchFilter(searchValue, inProgressData);
      const filteredReviewData = searchFilter(searchValue, reviewData);
      setFilteredInProgressData(filteredInProgressData);
      setFilteredReviewData(filteredReviewData);
  }, [searchValue]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabActiveKey(tab);
    }
  }, []);

  const onDelete = () => {
    setDeleteLoader(true);

    let payload = {
        "username": CUSTOMER_USERNAME,
        "project_id": currentProjectId
    }

    if(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == 'co_owner'){
        payload["action"] = "delete_project"
    }
    else{
        payload["action"] = "hide_project"
    }

    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload)
        .then((response) => {
            // Retrieve the counts array from localStorage
            const counts = JSON.parse(localStorage.getItem("entity_counts"));

            if (counts) {
            const projectEntry = counts.find(entity => entity.type === tabActiveKey);

            if (projectEntry && projectEntry.count > 0) {
                projectEntry.count -= 1;
            }

            // Store the updated counts array back in localStorage
            localStorage.setItem("entity_counts", JSON.stringify(counts));
            } else {
            console.error("Counts not found in localStorage.");
            }

            message.info('Project Deleted.');
            setDeleteLoader(false);
            setDeleteModal(false);
            window.location.href = 'projects?tab=' + tabActiveKey;
        })
        .catch((error) => {
            console.log(error);
            message.error('Error in deleting project');
            setDeleteLoader(false);
            setDeleteModal(false);
        });
  }

  return (
    <div className="my-products">
      <CustomerMainLayout selected="21">
      <Header style={{paddingLeft:"0px"}} className='product-nav-header projects-nav-bar'>
      <Row className='display-flex j-s-b a-c'>
        <Col span={12}>
          <Breadcrumb className='display-flex j-s a-c'>
            <Breadcrumb.Item >
              <a
                className='manrope f-14 lh-28 black-73 white-space-text'
                href={`/`}
              >
                <ArrowLeftOutlined className='mr-8' />
                  Dashboard
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item className='manrope f-14 lh-28 black-d9 limit-lines limit-1'>
              Project Activity
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
    </Header>
        <Row
          className="justify-space-between"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <>
            <Col lg={10} xl={10}>
              <div
                className="manrope f-20 w-600"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                Project Activity
              </div>
            </Col>
            <Col lg={12} xl={12} className="justify-in-end">
              <Col lg={15} xl={15}>
                <Form>
                  <Input.Group compact size="large" className="d-inline">
                    <Input
                      placeholder="Search by product, space, etc..."
                      onChange={(e) => {setSearchValue(e.target.value)}}
                      className="manrope f-14 mat-search-bar"
                      style={{ marginLeft: IS_MS_PROVIDER ? 5 : "" }}
                    ></Input>
                  </Input.Group>
                </Form>
              </Col>
            </Col>
          </>
        </Row>
        <div>
              <span>
                <Col span={24}>
                <Tabs
                  onChange={(key) => handleTabChange(key)}
                  className="product-tab library"
                  tabBarGutter={16}
                  size="large"
                  activeKey={tabActiveKey}
                >
                  {
                    tabs?.map((tab) => {
                      return (
                        <TabPane tab={tab.title + (isDataLoading ? tempProjectCount.find(entity => entity.type === tab.key)?.count ? ` (${tempProjectCount.find(entity => entity.type === tab.key)?.count})` : '' : ` (${tab.data.length})` )} key={tab.key} style={{ padding: "0px 10px" }}>
                          <div className="product-tab library">
                            {
                              isDataLoading ? <DottedLoader /> : 
                              <ProjectsList 
                                data={tab.data} 
                                setDeleteModal={setDeleteModal}
                                setCurrentProjectId={setCurrentProjectId}
                                setCurrentProjectAccessLevel={setCurrentProjectAccessLevel}
                                tabActiveKey={tabActiveKey}
                                />
                            }
                          </div>
                        </TabPane> )
                  })
                  }
                  </Tabs>
                </Col>
              </span>
              
        </div>
      </CustomerMainLayout>
      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={deleteModal}
        closable={false}
        footer={[
            <span className="justify-in-center">
                <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                    Cancel
                </Button>
                <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => onDelete()}>
                    {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span>Yes, Delete Project </span>}
                </Button>
            </span>
        ]}>
        <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <h2 className="manrope f-16 black-00 w-600">{(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == "co_owner") ? 
            "You are about to delete this project" : "You are about to Delete this project for your account."}</h2>
        <p className="manrope f-14 black-55">
            {(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == "co_owner") ? 
            "Since you are the owner of this project, it will be deleted for all users." : "Since you were not the owner of this project, it will still be visible to other users"}
        </p>
        </div>
    </Modal>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(DashboardProjects));
